import React from "react";

const FormTambahItem = ({ itemBelanja, setItemBelanja, handleForm }) => {
  return (
    <form onSubmit={handleForm}>
      <div className="mb-3">
        <label htmlFor="itemBelanja" className="form-label">
          Item Belanja
        </label>
        <input
          type="text"
          className="form-control col-md-3"
          id="itemBelanja"
          onChange={(e) => setItemBelanja(e.target.value)}
          value={itemBelanja}
        />
      </div>
      <input type="submit" value="Simpan Item" />
    </form>
  );
};

export default FormTambahItem;
