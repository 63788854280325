import React from "react";

const ItemDetail = ({ item, changeStatus, deleteItem }) => {
  const style1 = {
    color: "red",
  };

  const style2 = {
    color: "blue",
  };

  const getStyle = () => {
    return item.complete ? style2 : style1;
  };

  return (
    <>
      <li style={getStyle()} onClick={() => changeStatus(item.Id)}>
        {item.item}{" "}
      </li>{" "}
      <span onClick={() => deleteItem(item.Id)}>x</span>
      {/* <span>{JSON.stringify(item)}</span> */}
    </>
  );
};

export default ItemDetail;
