import React, { useState } from "react";

import FormTambahItem from "./FormTambahItem";
import ItemDetail from "./ItemDetail";

const ListBelanja = () => {
  const [itemBelanja, setItemBelanja] = useState("");
  const [listItemBelanja, setListItemBelanja] = useState([]);
  const [itemId, setItemId] = useState(0);

  /* 
    sebelumnya listItemBelanja = ["Beras"]
    diubah menjadi listItemBelanja = [{item: "Beras", complete: false}]
  */

  const handleForm = (e) => {
    e.preventDefault();
    if (itemBelanja !== "") {
      setListItemBelanja([
        ...listItemBelanja,
        {
          Id: itemId + 1,
          item: itemBelanja,
          complete: false,
        },
      ]);
      setItemBelanja("");
      setItemId(itemId + 1);
    } else {
      alert("Item Harus Ada Isi");
    }
  };

  const totalItem = () => {
    return listItemBelanja.length;
  };

  const changeStatus = (itemId) => {
    const newListItemBelanja = listItemBelanja.map((item) => {
      if (item.Id === itemId) {
        return {
          ...item,
          complete: !item.complete,
        };
      }
      return item;
    });

    setListItemBelanja(newListItemBelanja);
  };

  const deleteItem = (itemId) => {
    const newListItemBelanja = listItemBelanja.filter((item) => {
      return item.Id !== itemId;
    });

    setListItemBelanja(newListItemBelanja);
  };

  return (
    <>
      <div className="container">
        <h1>List Belanja </h1>
        <hr />
        <FormTambahItem
          itemBelanja={itemBelanja}
          setItemBelanja={setItemBelanja}
          handleForm={handleForm}
        />
      </div>
      <div className="container">
        {/* <span>{listItemBelanja.join(",")}</span> */}
        <ul>
          {listItemBelanja.map((item, index) => {
            return (
              <ItemDetail
                item={item}
                key={index}
                changeStatus={changeStatus}
                deleteItem={deleteItem}
              />
            );
          })}
        </ul>

        <span>Total Belanja : {totalItem()} item</span>
      </div>
    </>
  );
};

export default ListBelanja;
